import React from 'react';

function ClubByLaws() {
  return (
    <div className="container">
      <iframe 
        src="https://drive.google.com/file/d/1OAW2iEPyw_6PEJUQPIlcmuFE5bKqXDQK/preview" 
        style={{width: '100%', height: '100vh'}}
        allow="autoplay"
        frameBorder="0"
      />
    </div>
  );
}

export default ClubByLaws;
