import { Margin } from '@mui/icons-material'
import React from 'react'

function PressReleases() {
  return (
    <div>
            <div className="container rowpad1">
        <div className='col' style={{paddingTop: "50px"}}>
                  <p className="maintitle">Press Releases</p>
              </div>

        <div className='row' data-aos="fade-up">
        <p className="pressreltitle" style={{marginTop: '15px'}}><b>CAMBRIDGE, MASSACHUSETTS --(Samyak Science Society)-- June 3, 2024 --</b></p>

        <div>
          <p className="pressrelcontent">
            <b>Fifteen High Schoolers Compete at the 2024 International Research Olympiad Finals</b>
          </p>

          <p className="pressrelcontent">
            June 2nd, 2024, Cambridge, MA — The International Research Olympiad featured over 1,000 participants from 45 countries. After several rounds of examinations, fifteen finalists competed at the Finals event from May 31 to June 2 in Cambridge, MA.
          </p>

          <p className="pressrelcontent">
            The International Research Olympiad (IRO) is dedicated to honing analytical and critical thinking skills for student researchers—essential in scientific exploration. The IRO fosters a culture of rigorous research and skill-building, not just memorizing formulae. The practical applications of knowledge and big-picture thinking are tested, allowing students to walk away with a deeper passion for scientific inquiry and engineering.
          </p>

          <p className="pressrelcontent">
            Contestants may be awarded gold, silver and bronze medals at the finals event.
          </p>

          <p className="pressrelcontent">
            The finalists represent the top 1% of competitors, having scored highest at the IRO Semifinals and Opens Exams. By emphasizing research and critical thinking at such a formative stage, the IRO is crafting the next generation of scientists, innovators, and thought leaders. These students will go on to tackle global challenges, making significant contributions to science and society.
          </p>

          <p className="pressrelcontent">
            <b>Gold Medal:</b>
          </p>
          <p className="pressrelcontent">
            Jaansi Patel from Edison, New Jersey, of Phillips Exeter Academy, won 1st Place.
          </p>

          <p className="pressrelcontent">
            <b>Silver Medal:</b>
          </p>
          <p className="pressrelcontent">
            Edward Kang from Upper Saddle River, New Jersey, of Bergen County Academies won 2nd Place and a Silver Medal. <br/> Antara Chhabra from Bengaluru, India, of Shri Ram Global School won 3rd Place and a Silver Medal.
          </p>

          <p className="pressrelcontent">
            <b>Bronze Medal:</b>
          </p>
          <p className="pressrelcontent">
            Vishnu Mangipudi from Seattle, Washington, of Lakeside School won 4th Place. <br/> Ruuhan Anand from Singapore, of Raffles Institution, won 5th Place. <br/> Ethan Sullivan from Miami, Florida, of Ransom Everglades School won 6th Place.
          </p>

          <p className="pressrelcontent">
            IRO Special Awards include the Public Science Communication Award and the Team Award. While not deciding factors of the grand awards, they help further IRO’s dedication towards fostering scientific research in the changemakers of tomorrow.
          </p>

          <p className="pressrelcontent">
            <b>Public Science Communication Award:</b>
          </p>
          <p className="pressrelcontent">
            The recipient of this award was chosen by IRO’s community of over 25,000 students cumulatively. Finalists created a five minute video explaining a particularly interesting research topic to the general public.
          </p>

          <p className="pressrelcontent">
            The winner was Edward Kang from Upper Saddle River, New Jersey, whose video appealed to the public the most, displaying creativity, a well-balanced and eloquent presentation, and a passion for scientific research.
          </p>

          <p className="pressrelcontent">
            <b>Team Award:</b>
          </p>
          <p className="pressrelcontent">
            The team award was given to the team that best demonstrated cohesiveness, collaboration, and a dedication to the scientific research pursuit.
          </p>

          <p className="pressrelcontent">
            <b>The winners were:</b>
          </p>
          <p className="pressrelcontent">
            Ethan Sullivan from Miami, Florida, of Ransom Everglades School;
            Antara Chhabra from Bengaluru, India, of Shri Ram Global School;
            Amit Bhatt from Iowa City, Iowa, of Iowa City West High School.
          </p>

          <p className="pressrelcontent">
            Rishab Kumar Jain, President and Chair of the Student Board of Advisors to the International Research Olympiad said “What sets the IRO apart is our commitment to critical thinking, which is essential for scientific research. We’ve seen a high number of contestants who possess extraordinary potential—and who are serious and motivated about their research-based, real-world interests.”
          </p>

          <p className="pressrelcontent">
            “The IRO Finals took place in a time like no other, and students showed a level of adaptability and resilience that was remarkable,” said Executive & Education Coordinator Yashvir Sabharwal. “Each student tackled a crucial issue, and presented their solutions so that they would matter to the world today.”
          </p>

          <p className="pressrelcontent">
            The 2024 IRO Finals showcased some of the most promising young minds in the world. With its dedication to critical thinking and real-world application of knowledge, the IRO is shaping the next generation of innovative leaders who will tackle global challenges and make a lasting impact on society.
          </p>
        </div>


        </div>
        
      </div>
      <div className="container ">

        <div style={{margin: "15%"}} className='row' data-aos="fade-up">
          <p className="pressreltitle"><b>CAMBRIDGE, MASSACHUSETTS --(Samyak Science Society)-- Jan 3, 2024 --</b></p>

          <p className="pressrelcontent">
            The Samyak Science Society is pleased to announce the creation of the International Research Olympiad: the world's first olympiad-style competition for high school students dedicated solely to scientific research. The International Research Olympiad (IRO) aims to inspire and challenge students to pursue scientific research in diverse fields and foster a culture of learning. The competition is open to all thirteen through eighteen-year-olds around the globe and will encourage students to improve critical thinking, scientific reading, and science communication skills.

          </p>

          <p className="pressrelcontent">

          The Samyak Science Society, a non-profit organization established in 2019, aims to promote science and scientific thinking among young minds. In line with this mission, the IRO will allow students to showcase their skills and creativity in diverse scientific areas. The competition will consist of four rounds, including a written test, a research proposal submission, a research project presentation, and more unique events. There will be two rounds of open exams, a semifinalist round, and a final round, after which medalists will be determined. Further details of the competition format and rules will be announced on the IRO website.



          </p>

          <p className="pressrelcontent">

          The IRO is engaging in key partnerships with organizations to provide research mentorship opportunities for students and encourage students to explore their original work after the competition.

            
          </p>

          <p className="pressrelcontent">

          The IRO's organizers hope the competition will foster a sense of global community and collaboration among students interested in scientific research. "We believe that the International Research Olympiad will not only encourage and recognize exceptional research talent but also inspire students from all backgrounds to develop their scientific curiosity and explore the world around them," said Rishab Jain, President of the IRO.

            
          </p>

          <p className="pressrelcontent">

          The first open exam round for the IRO is scheduled to take place in March 2024, with the top performers advancing to the semifinalist round in March 2024. The final round of the competition will be held in late March or April 2024, culminating in the announcement of medalists and special award recipients.

            
          </p>

          <p className="pressrelcontent">

          The Samyak Science Society invites all high school students interested in science and research to participate in the International Research Olympiad and join them in their mission to foster a love for scientific thinking and exploration.


            
          </p>

        </div>
        
      </div>
    </div>
  )
}

export default PressReleases