import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Navbar from './Navbar';
import { BrowserRouter } from 'react-router-dom';
import Footer from './Footer';
import { StateProvider } from "./StateProvider";
import reducer, { intialState } from "./reducer";
import Login from './Login'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StateProvider intialState={intialState} reducer={reducer}>
      <BrowserRouter>

        <App />

      </BrowserRouter>
    </StateProvider>
  </React.StrictMode>
);

