import React from 'react';

function ClubRegistration() {
  return (
    <div>
      <div className="container">
       

<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdU6WZhqU_csYHvBnsSacYd2KdES49VZvdActyBaYw8mjmQmg/viewform?embedded=true" style={{width: '100%', height: '100vh'}} frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      </div>
    </div>
  );
}

export default ClubRegistration;
