import React from 'react'
import Alert from '@mui/material/Alert';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


function MediaKit() {

  const gotostripe = () => {
    window.location.href = "https://register.internationalresearcholympiad.org/b/eVadSR8SDexrfhmdQS"
    
  }

  return (
    <div>
      <div className="container">
        <div className='row rowpad1'>
            <div className='col' style={{paddingTop: "50px"}} data-aos="fade-up">
              <p className="maintitle bigblueonthewing">Media Kit + Branding</p>
            </div>
          </div>
          
          <div className='row' data-aos="fade-up" style={{marginTop: '30px'}}>

          {/* <div style={{paddingBottom: "30px"}} data-aos="fade-up">
            <Alert style={{width: "70%"}} severity="info">IRO will release a detailed press kit for distribution to the media when registration opens.</Alert>
          </div> */}
        
            {/* <div className='col' data-aos="fade-up">
            <Skeleton variant="rectangular" width={500} height={520} />
            </div>

            <div className='col' data-aos="fade-up">
              <div className="row">
              <Skeleton variant="rectangular" width={500} ht={250} />

              </div>
              <div className="row" style={{paddingTop: "20px"}}>
              <Skeleton variant="rectangular" max/>

              </div>
            </div> */}
          </div>
          <div
            className="canvaformediakit"
            style={{
              position: "relative",
              width: "100%",
              height: 0,
              paddingTop: "56.25%",
              paddingBottom: 0,
              boxShadow: "0 2px 8px 0 rgba(63, 69, 81, 0.16)",
              marginTop: "1.6em",
              marginBottom: "0.9em",
              overflow: "hidden",
              borderRadius: "8px",
              willChange: "transform"
            }}
          >
            <iframe
              loading="lazy"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                border: "none",
                padding: 0,
                margin: 0
              }}
              src="https://www.canva.com/design/DAF6jRjldog/view?embed"
              allowFullScreen
              allow="fullscreen"
            ></iframe>
          </div>


          <div className="row rowpad1" style={{alignItems: "center", justifyContent: "center"}}>
     
         

        </div>
          

      </div>
    </div>
  )
}

export default MediaKit