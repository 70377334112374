import './App.css';
import LazyLoad from 'react-lazyload';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import logo from './irofullpng.webp'; 
import section1 from "./irosection1.png";
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { Link } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import LightModeIcon from '@mui/icons-material/LightMode';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import Grid from '@mui/material/Grid';
import { HashLink } from 'react-router-hash-link';

import { useState, useEffect, forwardRef, Fragment } from 'react';
import firebase from "firebase/compat/app";
import db from './firebase'
import { Margin } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import {auth, provider} from './firebase';
import { actionTypes } from "./reducer";
import { useStateValue } from "./StateProvider";
import './Login.css';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import AOS from "aos";

import Autocomplete from '@mui/material/Autocomplete';


// import { Popover, Transition } from '@headlessui/react'
// import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
// import {
//   ArrowPathIcon,
//   ChartPieIcon,
//   CursorArrowRaysIcon,
//   FingerPrintIcon,
//   SquaresPlusIcon,
// } from '@heroicons/react/24/outline'

// const solutions = [
//   { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: ChartPieIcon },
//   { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
//   { name: 'Security', description: "Your customers' data will be safe and secure", href: '#', icon: FingerPrintIcon },
//   { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: SquaresPlusIcon },
//   { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: ArrowPathIcon },
// ]
// const callsToAction = [
//   { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
//   { name: 'Contact sales', href: '#', icon: PhoneIcon },
// ]

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Navbar() {
    const navigate = useNavigate() 
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const lightOrDarkSave = JSON.parse(localStorage.getItem('lightOrDarkSave')) || "light";
    const [theme, setTheme] = useState(lightOrDarkSave);
    const [open, setOpen] = useState(false);
    const [state, dispatch] = useStateValue();
    const [buttonTwo, setButtonTwo] = useState(true)
    const [buttonThree, setButtonThree] = useState(true);
    const [buttonFour, setButtonFour] = useState(true);
    const [age, setAge] = useState('');
    const [buttonFive, setButtonFive] = useState(true);
    const [buttonSix, setButtonSix] = useState(true);
    const [buttonSeven, setButtonSeven] = useState(true);
    const [buttonEight, setButtonEight] = useState(true);

    const [realbuttonclick, setRealbuttonclick] = useState(false);
    const [realbuttonclickk, setRealbuttonclickk] = useState(false);
    const [realbuttonclickkk, setRealbuttonclickkk] = useState(false);
    const [realbuttonclickkkk, setRealbuttonclickkkk] = useState(false);
    const [realbuttonclickkkkk, setRealbuttonclickkkkk] = useState(false);
    const [realbuttonclickkkkkk, setRealbuttonclickkkkkk] = useState(false);
    const [realbuttonclickkkkkkk, setRealbuttonclickkkkkkk] = useState(false);

    const toggleTheme = () => {
        if (theme === "light") {
        setTheme("dark");
        localStorage.setItem('lightOrDarkSave', JSON.stringify("dark"));
        } else {
        setTheme("light");
        localStorage.setItem('lightOrDarkSave', JSON.stringify("light"));
        }
    };
    useEffect(() => {
        document.documentElement.classList = theme;
    }, [theme]);
 
    useEffect(() => {
      AOS.init({ duration: 2000 }); //might need to be AOS
    }, []);
  


    const handleClose = () => {
      setOpen(false);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const AboutButton = () => {
      navigate('/about')
      setOpen(false);

      setButtonTwo(false);
      setButtonThree(false);
      setButtonFour(false);
      setButtonFive(true);
      setButtonSix(false);
      setButtonSeven(false);
      setButtonEight(false);
      setRealbuttonclickkkk(true);
      setRealbuttonclickkk(false);
      setRealbuttonclickk(false);
      setRealbuttonclick(false);
    }

    const rulesandreg = () => {
      navigate("/rules-and-regulations")
      handleClose(true)
    }
    
    const twentfinalist = () => {
      navigate("/finalists")
      handleClose(true)
    }
    const twentsemifinalist = () => {
      navigate("/semis-results")
      handleClose(true)
    }
    const twentsemiwinners = () => {
      navigate("/winners")
      handleClose(true)
    }
    const aboutexam = () => {
      navigate("/about-iro")
      handleClose(true)
    }
    const regreg = () => {
      navigate("/registration-and-eligibility")
      handleClose(true)
    }

    const letsgotwobut = () => {
      navigate("/about")
      handleClose(true)
    }

    const startclub = () => {
      navigate("/start-iro-club")
      handleClose(true)
    }
    const praticeexam = () => {
      navigate("/practice-exams")
      handleClose(true)
    }

    const spraticeexam = () => {
      navigate("/semifinal-practice-exams")
      handleClose(true)
    }
    const scurriculssme = () => {
      navigate("/semi-curriculum")
      handleClose(true)
    }

    const curriculssme = () => {
      navigate("/curriculum")
      handleClose(true)
    }

    const clubRegistration = () => {
      navigate("/forms/club-registration")
      handleClose(true)
    }

    const clubDirectory = () => {
      navigate("/directory")
      handleClose(true);
    }

    const clubPresentation = () => {
      navigate("/files/club-presentation-slides")
      handleClose(true)
    }

    const clubBylaws = () => {
      navigate("/files/club-bylaws")
      handleClose(true)
    }

    const peressrelase = () => {
      navigate("/press-releases")
      handleClose(true)
    }
    const indanews = () => {
      navigate("/iro-in-the-news")
      handleClose(true)
    }
    const medieakit = () => {
      navigate("/media-kit")
      handleClose(true)
    }
    const mediaeoutreach = () => {
      navigate("/media-contact")
      handleClose(true)
    }
 

    const loginpage = () => {
      navigate("/login")
      handleClose(true)

      setButtonTwo(false);
      setButtonThree(false);
      setButtonFour(false);
      setButtonFive(false);
      setButtonSix(false);
      setButtonSeven(false);
      setButtonEight(true);
      setRealbuttonclickkkkkkk(true);
      setRealbuttonclickkkkkk(false);
      setRealbuttonclickkkkk(false);
      setRealbuttonclickkkk(false);
      setRealbuttonclickkk(false);
      setRealbuttonclickk(false);
      setRealbuttonclick(false);
    }

    const IROExamInformation = () => {
      setButtonTwo(true);
      setButtonThree(false);
      setButtonFour(false);
      setButtonFive(false);
      setButtonSix(false);
      setButtonSeven(false);
      setButtonEight(false);
      setRealbuttonclick(true);
      setRealbuttonclickkkkkkk(false);
      setRealbuttonclickkkkkk(false);
      setRealbuttonclickkkkk(false);
      setRealbuttonclickkkk(false);
      setRealbuttonclickkk(false);
      setRealbuttonclickk(false);
    }
    const StudentCenter = () => {
      setButtonThree(true);
      setButtonTwo(false);
      setButtonFour(false);
      setButtonFive(false);
      setButtonSix(false);
      setButtonSeven(false);
      setButtonEight(false);
      setRealbuttonclickk(true);
      setRealbuttonclickkkkkkk(false);
      setRealbuttonclickkkkkk(false);
      setRealbuttonclickkkkk(false);
      setRealbuttonclickkkk(false);
      setRealbuttonclickkk(false);
      setRealbuttonclick(false);
    }
    const Media = () => {
      setButtonFour(true);
      setButtonTwo(false);
      setButtonThree(false);
      setButtonFive(false);
      setButtonSix(false);
      setButtonSeven(false);
      setButtonEight(false);
      setRealbuttonclickkk(true);

      setRealbuttonclickkkkkkk(false);
      setRealbuttonclickkkkkk(false);
      setRealbuttonclickkkkk(false);
      setRealbuttonclickkkk(false);
      
      setRealbuttonclickk(false);
      setRealbuttonclick(false);
    }

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    
  const gotostripe = () => {
    window.location.href = "https://register.internationalresearcholympiad.org/b/eVadSR8SDexrfhmdQS"
    
  }

  const signInButton = () => {
    window.open("https://exam.internationalresearcholympiad.org/", "_blank");
  }
  
  

  return (
    <div>
        <nav class="navbar bg-body-tertiary">
        <div class="container-fluid highlightnavbar">
          <a class="navbar-brand" href="/">
              <LazyLoad height={50} offset={100} once>
                <img src={logo} alt="Logo" width="50" className="d-inline-block align-text-top"/>
            </LazyLoad>
          
          </a>
          <div class="d-flex">
            <button className="btn-sunbutton" type="submit" onClick={toggleTheme}>
              <div className="Icon-Transition">
                {theme !== 'dark' ?
                  <div className="Light-Icon-Transition">
                    <LightModeIcon style={{fontSize: '17px'}}/>
                  </div>
                :
                  <div className="Dark-Icon-Transition">
                    <BedtimeIcon style={{fontSize: '17px'}}/>
                  </div>
                }
              </div>           
            </button>

            <a href="https://discord.gg/XSHssgwWzB" target="blank" className="discord-link">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-discord discord-link-svg" viewBox="0 0 16 16">
              <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
            </svg>
            </a>
            <button className="btn-Homebutton" type="submit"><a href="/" style={{color:'black', textDecoration:'none'}}>Home</a></button>
            <button className="btn-aboutbutton" type="submit" onClick={AboutButton}>About the IRO</button>
            <div class="btn-group">
              <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              IRO Exam Information
              </button>
              <ul style={{}} class="dropdown-menu">
              <li><a class="dropdown-item" href="/about-iro">About the Exam</a></li>
              <li><a class="dropdown-item" href="/winners">2024 Winners</a></li>
              <li><a class="dropdown-item" href="/finalists">2024 Finalists</a></li>
              <li><a class="dropdown-item" href="/semis-results">2024 Semi-Finalists</a></li>
              
                <li><a class="dropdown-item" href="/rules-and-regulations">Rules and Regulations</a></li>
                <li><a class="dropdown-item" href="registration-and-eligibility">Registration and Eligibility</a></li>
              </ul>
            </div>

            <div class="btn-group">
              <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              Student Center
              </button>
              <ul style={{}} class="dropdown-menu">
                <li><a class="dropdown-item" href="/start-iro-club">How to Start an IRO Club</a></li>
                <li><a class="dropdown-item" href="/semifinal-practice-exams">Mock Semi Exams</a></li>
                <li><a class="dropdown-item" href="/practice-exams">Mock Open Exams</a></li>
                <li><a class="dropdown-item" href="/semi-curriculum">Semi Exam Curriculum</a></li>
                <li><a class="dropdown-item" href="/curriculum">Open Exam Curriculum</a></li>
                <li><a class="dropdown-item" href="/forms/club-registration">Club Registration Form</a></li>
                <li><a class="dropdown-item" href="/files/club-bylaws">Research Club Bylaws</a></li>
                <li><a class="dropdown-item" href="/files/club-presentation-slides">Club Presentation Slides</a></li>
                <li><a class="dropdown-item" href="/directory">Club Directory</a></li>
              </ul>
            </div>

            <div class="btn-group">
              <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              Media
              </button>
              <ul style={{}} class="dropdown-menu">
                <li><a class="dropdown-item" href="/press-releases">Press Releases</a></li>
                <li><a class="dropdown-item" href="/iro-in-the-news">In the News</a></li>
                <li><a class="dropdown-item" href="/media-kit">Media Kit + Branding</a></li>
                <li><a class="dropdown-item" href="/media-contact">Media Contact</a></li>
                
                {/* <HashLink to="/page#section-one">Section One</HashLink> */}
             
              </ul>
            </div>



            {/* <button className="btn-resourcesbutton" type="submit" onClick={ResourcesButton}>About the IRO</button>
            <button className="btn-resourcesbutton" type="submit" onClick={ResourcesButton}>IRO Exam Information</button>
            <button className="btn-resourcesbutton" type="submit" onClick={ResourcesButton}>Student Center</button>
            <button className="btn-resourcesbutton" type="submit" onClick={ResourcesButton}>Media</button> */}

            {/* <Popover className="relative">
                <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                  <span>Solutions</span>
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Popover.Button>

                  <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                    <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                      <div className="p-4">
                        {solutions.map((item) => (
                          <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                            <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                              <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                            </div>
                            <div>
                              <a href={item.href} className="font-semibold text-gray-900">
                                {item.name}
                                <span className="absolute inset-0" />
                              </a>
                              <p className="mt-1 text-gray-600">{item.description}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                        {callsToAction.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"
                          >
                            <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </Popover.Panel>
               
              </Popover> */}
            
            <button onClick={handleClickOpen} className="btn-morebutton"> <MenuOutlinedIcon style={{color: 'black'}}/> </button>
            {/* mailto:iro@samyakscience.com */}
            <button className="btn-contactbutton" style={{width: "130px"}} type="submit"> <a href=" mailto:iro@samyakscience.com " style={{color: 'white', textDecoration: 'none' }}>Contact</a> <CallMadeOutlinedIcon style={{fontSize: '15px', marginLeft:'2px'}}/></button>
            {/* <button className="btn-signInbutton" style={{width: "130px"}} type="submit" onClick={signInButton}>Sign In</button> */}
            
            
            {/* <div>
              <button className="btn-DensityMediumIconbutton" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" type="submit"><DensityMediumIcon style={{color: 'black', fontSize: '20px'}}/></button>
              <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ width: '100vw', height: '100vh' }}>
                <div class="offcanvas-header">
                  <h3 id="offcanvasRightLabel">More</h3>
                  <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                </div>
                <div class="offcanvas-body">
                  <a href="https://discord.gg/XSHssgwWzB" target="blank" className="discord-link-two" style={{color:'black'}}>
                    <button className="side-bar-popover-button-css" style={{color:'black'}}> 
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" style={{color: "black", marginTop: '1px;'}} fill="currentColor" class="bi bi-discord discord-link-svg" viewBox="0 0 16 16">
                        <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
                      </svg> Discord
                    </button>
                  </a>
                  <button className="side-bar-popover-button-css"  data-bs-dismiss="offcanvas" onClick={AboutButton} aria-label="Close" style={{color:'black'}}><NewspaperIcon style={{color:'black'}}/> About</button>
                  <button className="side-bar-popover-button-css"  data-bs-dismiss="offcanvas" onClick={ResourcesButton} aria-label="Close" style={{color:'black'}}><Diversity1Icon style={{color:'black'}}/> Resources</button>
                  <button className="side-bar-popover-button-css"><a href=" mailto:iro@samyakscience.com " style={{color: 'black', textDecoration: 'none' }}><ArrowOutwardOutlinedIcon/> Contact</a></button>
                  <button className="side-bar-popover-button-css" style={{ color: 'black'}} type="submit" onClick={() => navigate('/Login')}> Sign In</button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </nav>
      <div className="coolhover-test"></div>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: 'white', color: 'black', boxShadow: 'none'}} data-aos="fade-down" data-aos-duration="800">
          <Toolbar>
            <Typography sx={{ ml: 4, flex: 1, marginTop: '20px' }} variant="h6" component="div">
              <a class="navbar-brand" href="/">
                <LazyLoad height={100} offset={100} once>
                    <img src={logo} alt="Logo" width="100" className="d-inline-block align-text-top"/>
                </LazyLoad>
              </a>
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose} className="dhfgaskjhdgasdhjfgasjdgfaskjdhfghjaskdfkasjdhgasdf"
              style={{
                textTransform: 'none', 
                marginTop: '40px', 
                marginRight: '40px',
              }} 
              sx={{
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'transparent', // Set the background color to transparent on hover
                },
              }}
            >
              <span style={{fontSize: '23px'}}>Close </span> &nbsp; &nbsp; &nbsp;
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                style={{border: '1px solid black', width:'30px', height: '30px'}}
                sx={{
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'transparent', // Set the background color to transparent on hover
                  },
                }}
              >
                <CloseIcon style={{fontSize: '18px'}}/>
              </IconButton>
            </Button>
          </Toolbar>
        </AppBar>

      <div className="mainscreenmenu">

      <Grid  container spacing={2}>
        <Grid item xs={4}>
        
            <List>
            <div data-aos="fade-down" data-aos-duration="500">

              {buttonTwo ? 
                <>
                  {realbuttonclick ?
                  
                    <button className="side-bar-2-popover-button-csss underline-animationn" style={{color:'black'}} onClick={IROExamInformation}>Exam Information</button>
                    
                  :
                    <button className="side-bar-2-popover-button-csss underline-animation" style={{color:'black'}} onClick={IROExamInformation}>Exam Information</button>
                  }
                </>
              :
                <button className="side-bar-2-popover-button-csss underline-animation" style={{color:'grey'}} onClick={IROExamInformation}>Exam Information</button>
              }
              
              <br/>
              {buttonThree ? 
                <>
                  {realbuttonclickk ?
            
                  <button className="side-bar-2-popover-button-css underline-animationn"  style={{color:'black'}} onClick={StudentCenter}>Student Center</button>
                
                    
                  :
                    <button className="side-bar-2-popover-button-css underline-animation"  style={{color:'black'}} onClick={StudentCenter}>Student Center</button>
                  }
                </>
              :
                <button className="side-bar-2-popover-button-css underline-animation"  style={{color:'grey'}} onClick={StudentCenter}>Student Center</button>
              }
        
              <br/>
              
              {buttonFour ? 
                <>
                  {realbuttonclickkk ?
                    <button className="side-bar-2-popover-button-css underline-animationn"  style={{color:'black'}} onClick={Media}>Media</button>
                  :
                    <button className="side-bar-2-popover-button-css underline-animation"  style={{color:'black'}} onClick={Media}>Media</button>
                  }
                </>
              :
                <button className="side-bar-2-popover-button-css underline-animation"  style={{color:'grey'}} onClick={Media}>Media</button>
              }
              <br/>
              {buttonFive ? 
                <>
                  {realbuttonclickkkk ?
                    <button className="side-bar-2-popover-button-css underline-animationn"  onClick={AboutButton} style={{color:'black'}}>About the IRO</button>
                  :
                    <button className="side-bar-2-popover-button-css underline-animation"  onClick={AboutButton} style={{color:'black'}}>About the IRO</button>
                  }
                </>
              :
                <button className="side-bar-2-popover-button-css underline-animation"  onClick={AboutButton} style={{color:'grey'}}>About the IRO</button>
              }
             
              {/* {buttonSix ? 
                <>
                  {realbuttonclickkkkk ?
                    <button className="side-bar-2-popover-button-css underline-animationn"  onClick={ResourcesButton} style={{color:'black'}}> Resources</button>
                  :
                    <button className="side-bar-2-popover-button-css underline-animation"  onClick={ResourcesButton} style={{color:'black'}}> Resources</button>
                  }
                </>
              :
                <button className="side-bar-2-popover-button-css underline-animation"  onClick={ResourcesButton} style={{color:'grey'}}> Resources</button>
              } */}
              <br/>
              {buttonSeven ? 
                <>
                  {realbuttonclickkkkkk ?
                    <button className="side-bar-2-popover-button-css underline-animationn"><a href=" mailto:iro@samyakscience.com " style={{color: 'black', textDecoration: 'none' }}>Contact</a></button>
                  :
                    <button className="side-bar-2-popover-button-css underline-animation"><a href=" mailto:iro@samyakscience.com " style={{color: 'black', textDecoration: 'none' }}>Contact</a></button>
                  }
                </>
              :
                <button className="side-bar-2-popover-button-css underline-animation"><a href=" mailto:iro@samyakscience.com " style={{color: 'grey', textDecoration: 'none' }}>Contact</a></button>
              }
              {/* <br/> */}
              {/* {buttonEight ? 
                <>
                  {realbuttonclickkkkkkk ?
                    <button className="side-bar-2-popover-button-css underline-animationn" style={{ color: 'black'}} type="submit" onClick={signInButton}> Sign In</button>
                  :
                    <button className="side-bar-2-popover-button-css underline-animation" style={{ color: 'black'}} type="submit" onClick={signInButton}> Sign In</button>
                  }
                </>
              :
                <button className="side-bar-2-popover-button-css underline-animation" style={{ color: 'grey'}} type="submit" onClick={signInButton}> Sign In</button>
              } */}

             
              <br/>
              <button className="side-bar-2-popover-button-css underline-animation" style={{color:'black'}}> 
                <a href="https://discord.gg/XSHssgwWzB" target="blank" className="discord-link-two" style={{color:'black', textDecoration:'none'}}>
                  Discord
                </a>
              </button>
            </div>
          </List>
       
        </Grid>
        {/* THis will be second col */}
        <Grid item xs={6}>
         
          <List>
            <div>

              {realbuttonclick &&
                <div style={{paddingTop: "30px"}} data-aos="fade-right" data-aos-duration="500">
                  
                  <p className="iei-title">2024 IRO Exam</p>
                  <p className="iei-summary">Explore key details of the 2024 International Research Olympiad (IRO) exam, <br/> including registration deadlines, exam format, and syllabus. It's an essential resource for students.</p>
                  <br/>
                  <button  className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={aboutexam}>About the Exam </button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button  className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={twentsemiwinners}>2024 Winners </button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button  className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={twentfinalist}>2024 Finalists </button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button  className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={twentsemifinalist}>2024 Semi-Finalists </button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  
                  <button className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={rulesandreg}>Rules and Regulations: ToS, Privacy Policy, Honor Code </button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={regreg}>Registration and Eligibility </button><ArrowForwardIcon style={{marginBottom: "4px"}}/>
                </div>
              }
              {realbuttonclickk &&
                <div data-aos="fade-right" data-aos-duration="500">
                   <p className="iei-title">IRO Student Center</p>
                  <p className="iei-summary">Student hub for the IRO of{""}fering resources for starting IRO clubs, accessing practice exams, and curriculum guidance, including literature review tutorials and instructional videos.</p>
                  <br/>
                  <button className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={startclub}>How to Start an IRO Club</button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={praticeexam}>Mock Open Exam</button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={spraticeexam}>Mock Semi Exam</button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={scurriculssme}>Semi Exam Curriculum</button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={clubRegistration}>Open Exam Curriculum </button><ArrowForwardIcon style={{marginBottom: "4px"}}/><br/><br/>
                  <button className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={clubBylaws}>Research Club Bylaws </button><ArrowForwardIcon style={{marginBottom: "4px"}}/><br/><br/>
                  <button className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={clubPresentation}>Club Presentation Slides </button><ArrowForwardIcon style={{marginBottom: "4px"}}/><br/><br/>
                  <button className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={clubRegistration}>Club Registration Form </button><ArrowForwardIcon style={{marginBottom: "4px"}}/>
                  <button className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={clubDirectory}>Club Directory </button><ArrowForwardIcon style={{marginBottom: "4px"}}/>
                </div>
              }
              {realbuttonclickkk &&
                <div data-aos="fade-right" data-aos-duration="500">
                   <div data-aos="fade-right" data-aos-duration="500">
                   <p className="iei-title">Media Opportunities</p>
                  <p className="iei-summary">Explore resources for journalists and the public, featuring Press Releases, IRO-related news coverage, a comprehensive Media Kit with branding guidelines, and a Contact section for media inquiries.</p>
                  <br/>
                  <button   className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={peressrelase}>Press Releases</button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}}  type="submit" onClick={indanews}>IRO in the News</button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button   className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={medieakit}>Media Kit + Branding</button><ArrowForwardIcon style={{marginBottom: "4px"}}/><br/><br/>
                  <button className="lowertitle secondinsideytwo underline-animation" style={{color:'black'}} type="submit" onClick={mediaeoutreach}>Contact</button><ArrowForwardIcon style={{marginBottom: "4px"}}/>
                </div>
                </div>
              }
             
             
            </div>
          </List>
        
        </Grid>
        
      </Grid>
       
      </div>
        
      

      {/* small screen */}

      <div className="smallscreenmenu" >

     
            <div style={{margin: "60px"}}>

               <div style={{paddingTop: "30px"}} >
                  
                  <p className="iei-title">About</p>
                 
                  <br/>
                  <button style={{color: "black"}}  className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={letsgotwobut}>About the IRO </button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  
                </div>

             
                <div style={{paddingTop: "30px"}} >
                  
                  <p className="iei-title">2024 IRO Exam</p>
                  <p className="iei-summary">Explore key details of the 2024 International Research Olympiad (IRO) exam, <br/> including registration deadlines, exam format, and syllabus. It's an essential resource for students.</p>
                  <br/>
                
                  <button style={{color: "black"}} className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={aboutexam}>About the Exam </button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button style={{color: "black"}} className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={twentsemiwinners}>2024 Winners </button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button style={{color: "black"}} className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={twentfinalist}>2024 Finalists </button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button style={{color: "black"}} className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={twentsemifinalist}>2024 Semifinalists </button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  
                  <button style={{color: "black"}} className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={rulesandreg}>Rules & Regulations </button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button style={{color: "black"}} className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={regreg}>Eligibility </button><ArrowForwardIcon style={{marginBottom: "4px"}}/>
                </div>
            
                <div style={{paddingTop: "30px"}} >
                   <p className="iei-title">IRO Student Center</p>
                  <p className="iei-summary">Student hub for the IRO of{""}fering resources for starting IRO clubs, accessing practice exams, and curriculum guidance, including literature review tutorials and instructional videos.</p>
                  <br/>
                  <button style={{color: "black"}} className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={startclub}>Start an IRO Club</button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button style={{color: "black"}} className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={praticeexam}>Practice Exams</button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button style={{color: "black"}} className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={curriculssme}>Curriculum </button><ArrowForwardIcon style={{marginBottom: "4px"}}/>
                </div>
       
                <div style={{paddingTop: "30px"}} >
                   <div >
                   <p className="iei-title">Media Opportunities</p>
                  <p className="iei-summary">Explore resources for journalists and the public, featuring Press Releases, IRO-related news coverage, a comprehensive Media Kit with branding guidelines, and a Contact section for media inquiries.</p>
                  <br/>
                  <button style={{color: "black"}} className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={peressrelase}>Press Releases</button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>
                  <button style={{color: "black"}} className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={indanews}>IRO in the News</button><ArrowForwardIcon style={{marginBottom: "4px"}}/> <br/><br/>                  <button style={{color: "black"}} className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={medieakit}>Media Kit</button><ArrowForwardIcon style={{marginBottom: "4px"}}/><br/><br/>
                  <button style={{color: "black"}} className="lowertitle secondinsideytwo underline-animation" type="submit" onClick={mediaeoutreach}>Contact</button><ArrowForwardIcon style={{marginBottom: "4px"}}/>
                </div>
                </div>

               

             

                
            
             
             
            </div>
          


      </div>

    
       
     
      </Dialog>
    </div>
  )
}

export default Navbar

