import '../App.css';
import LazyLoad from 'react-lazyload';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import logo from '../irofullpng.webp'; 
import section1 from "../irosection1.png";
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import LightModeIcon from '@mui/icons-material/LightMode';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';

import { useState, useEffect, forwardRef, Fragment } from 'react';
import firebase from "firebase/compat/app";
import db from '../firebase'
import { Margin } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import {auth, provider} from '../firebase';
import { actionTypes } from "../reducer";
import { useStateValue } from "../StateProvider";
import '../Login.css';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';


function NavbarTwo() {


    const [{ user }, dispatch] = useStateValue();
  
    const navigate = useNavigate() 
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const lightOrDarkSave = JSON.parse(localStorage.getItem('lightOrDarkSave')) || "light";
    const [theme, setTheme] = useState(lightOrDarkSave);
    
    const toggleTheme = () => {
        if (theme === "light") {
        setTheme("dark");
        localStorage.setItem('lightOrDarkSave', JSON.stringify("dark"));
        } else {
        setTheme("light");
        localStorage.setItem('lightOrDarkSave', JSON.stringify("light"));
        }
    };
    useEffect(() => {
        document.documentElement.classList = theme;
    }, [theme]);
 

    const handleLogout = () => {
        localStorage.removeItem('user');
        dispatch({ type: actionTypes.SET_USER, user: null });
        navigate('/');
    };

  return (
    <div>
        <nav class="navbar bg-body-tertiary">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <LazyLoad height={50} offset={100} once>
              <img src={logo} alt="Logo" width="50" className="d-inline-block align-text-top"/>
          </LazyLoad>
          
          </a>
          <div class="d-flex">
            <button className="btn-sunbutton" type="submit" onClick={toggleTheme}>
              <div className="Icon-Transition">
                {theme !== 'dark' ?
                  <div className="Light-Icon-Transition">
                    <LightModeIcon style={{fontSize: '17px'}}/>
                  </div>
                :
                  <div className="Dark-Icon-Transition">
                    <BedtimeIcon style={{fontSize: '17px'}}/>
                  </div>
                }
              </div>           
            </button>

          
            <button className="btn-resourcesbutton" type="submit" onClick={handleLogout}>Sign out</button>
            
          </div>
        </div>
      </nav>
    </div>
  )
}

export default NavbarTwo