import React from "react";
import LazyLoad from 'react-lazyload';
import {auth, provider} from './firebase';
import { actionTypes } from "./reducer";
import { useStateValue } from "./StateProvider";
import './Login.css';
import logo from './irofullpng.webp'; 
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useState, useEffect, forwardRef, Fragment } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/firestore'; // Import Firestore
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';


const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function Login(){

    const [failOpenMUI, setFailOpenMUI] = useState(false);
    const [state, dispatch] = useStateValue();
    const [failOpenMUII, setFailOpenMUII] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()

    const signInEmail = async () =>{

      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if ( re.test(email) ) {

        try {
          // Check if the email exists in the "IRO" collection

          const userSnapshot = await firebase.firestore().collection('IRO').where('email', '==', email).get();
    
          if (!userSnapshot.empty) {

            const uidSnapshot = await firebase.firestore().collection('IRO').where('uid', '==', password).get();

            if (!uidSnapshot.empty) {

              const user = userSnapshot.docs[0].data();
              console.log('User with email exists:', user);
              dispatch({ type: 'SET_USER', user: user });
              navigate('/Time');

            } else {
              console.log('User with email and UID does not match');
              setFailOpenMUI(true);
              setPassword('')
            }
            
          } else {
            console.log('User with email does not exist');
            setFailOpenMUII(true);
            setPassword('')
          }
        } catch (error) {
          console.error('Error checking user:', error.message);
          setFailOpenMUI(true);
          setPassword('')
        }

      } else {

        setFailOpenMUI(true);
        setPassword('')

      }


    }
    
    const handleCloseMUI = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setFailOpenMUI(false);
        setFailOpenMUII(false);
    };
    

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          signInEmail();
        }
    };
    

    return(
        <div className="black-box">
            <div className="black-box-content">
                <div className="irologo">
                    <LazyLoad height={100} offset={100} once>
                      <img src={logo} width="140px" alt="" />
                  </LazyLoad>
                </div>
                <p className="sign-in">Sign in</p>
                <div style={{paddingLeft: "20px", paddingRight: "20px"}} className="row">
                  <div class="mb-3">
                    <label for="exampleInputEmail1" style={{color: "black"}}>Email</label>
                    <input style={{borderRadius: "0"}} type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputPassword1" style={{color: "black"}}>Password</label>
                    <input style={{borderRadius: "0"}} type="password" class="form-control" id="exampleInputPassword1" value={password} onChange={(e) => setPassword(e.target.value)} onKeyPress={handleKeyPress} required/>
                  </div>
                  <div class="d-grid gap-2">
                    <button className="btn sign-in-buttonn" type="submit" onClick={signInEmail}>Sign In</button>
                  </div>
                  <Typography style={{paddingTop: "15px", color: "gray"}} variant="caption" display="block" gutterBottom>
                    Passwords will be sent out before the Open Exam. The email associated with your account is the same one you used when registering. If you have not received it, contact our support team for assistance.
                  </Typography>
                </div>
            </div>
            <div>
              <Snackbar open={failOpenMUI} autoHideDuration={3000} onClose={handleCloseMUI}>
                <Alert severity="error" sx={{ width: '100%' }}>
                  <b>Please enter a valid email.</b>
                </Alert>
              </Snackbar>

              <Snackbar open={failOpenMUII} autoHideDuration={3000} onClose={handleCloseMUI}>
                <Alert severity="error" sx={{ width: '100%' }}>
                  <b>This email does not exist</b>
                </Alert>
              </Snackbar>
            </div>
        </div>
    )
}