import React from 'react'
import "./PageNotFound.css"
import logo from "./iro404.png"

function PageNotFound() {
  return (
    <div>
        <div className="Oops-div">
            <p className="Oops">Oops! Something went wrong.</p>
            <p className="OopsTwo">You’re looking for a page that’s not here!</p>

            <div className="image-css-404">
              <img src={logo} alt="404" width="500" height="auto" className="image-css-invert-none"/>
            </div>
        </div>
    </div>
  )
}

export default PageNotFound