import React, { useEffect } from 'react'
import Jimmy from "../finalistsheadshot/jimmy.webp"
import Typography from '@mui/material/Typography';
import LazyLoad from 'react-lazyload';
import admit from "../finalistsheadshot/amit.webp"
import Ethan from "../finalistsheadshot/gt.webp"
import Jaansi from "../finalistsheadshot/pe.webp"
import Trashgim from "../finalistsheadshot/go.png"
import Sakethram from "../finalistsheadshot/wid.png"
import Edward from "../finalistsheadshot/cm.webp"
import Narendran from "../finalistsheadshot/sik.webp"
import Nguyen from "../finalistsheadshot/naugh.webp"
import Vishnu from "../finalistsheadshot/iad.webp"
import Ameya from "../finalistsheadshot/id.webp"
import Antara from "../finalistsheadshot/uil.webp"



const winners1 = [
  { id: 1, name: 'Trashgim Mulosmani', value: Trashgim },
  { id: 2, name: 'Jaansi Patel', value: Jaansi },
  { id: 3, name: 'Ethan Sullivan', value: Ethan },
  { id: 4, name: 'Sakethram Ramakrishnan', value: Sakethram },
  { id: 5, name: 'Edward Kang', value: Edward },
  { id: 6, name: 'Narendran Karikkathil', value: Narendran },
  { id: 7, name: 'Amit Bhatt', value: admit },
  { id: 8, name: 'Nguyen Hai Phong', value: Nguyen },
  { id: 9, name: 'Vishnu Mangipudi', value: Vishnu },
  { id: 10, name: 'Ameya Shirguppe', value: Ameya },
  { id: 11, name: 'Antara Chhabra', value: Antara },
  { id: 12, name: 'Justin Yuan', value: Jimmy },
  { id: 13, name: 'Ruuhan Anand', value: 'https://i.imgur.com/NAwse36.png' },
  { id: 14, name: 'Anisha Pandey', value: 'https://i.imgur.com/NAwse36.png' },
  { id: 15, name: 'Elaab Tsegaye', value: 'https://i.imgur.com/NAwse36.png' },
]

function Winners() {
  return (
      <div className="container">
        <div className='col' style={{paddingTop: "50px"}}>
        <Typography style={{fontSize: "1.5ch"}} variant="overline" display="block"  gutterBottom>
        5/5/24
          </Typography>
          <div className='col' style={{paddingTop: "50px"}}>
             <p className="maintitle bigblueonthewing">2024 IRO Finalists</p>
         </div>
           <p className="lowertitle">
            International Research Olympiad Finalists Announced.
           </p>

            <p className="lowertitle">
            <b>Congratulations to the finalists of the 2024 International Research Olympiad</b>. After several stages of evaluation, the top 15 scorers have been announced as finalists.
            </p>
           <div className="container">
           <i style={{fontSize: "17px"}}>
            "We stand committed to making this community into a close, innovative, welcoming, and supportive society around student research aspirations.
            </i>
            <i style={{fontSize: "17px"}}>
            {" "}On behalf of the trustees and advisors of the 2024 International Research Olympiad, I am pleased to announce that you have been selected as a finalist in this competition. It started at over 1,000 individuals, and after  several rounds of rigorous evaluation, your research abilities stood out as among the best in the world.
            </i>
            <br/><br/>
            <i style={{fontSize: "17px"}}>
            As someone who was involved in the final selection process based on the Oral Examination and other test scores, I can confidently say that your oral presentation was truly impressive, and it is clear that you have put a tremendous amount of effort and skill into your work. Each and every one of you brought a unique flavor to research— a skill that our scientific Board of Advisors encouraged us to seek out through this contest.
            </i>
            <br/><br/>
            <i style={{fontSize: "17px"}}>
            You will each receive a personalized email from a member of the IRO Director or Executive team who will guide you through the finalist process — a 1:1 line of reliable, personal communication. This individual will provide more details about your selection and the exciting next steps.
            </i>
            <br/><br/>
            <i style={{fontSize: "17px"}}>
            Personally, I want to take this moment to congratulate you on this achievement. As a current student who has greatly benefited from research and STEM competitions, I genuinely understand the hard work, dedication, and sacrifice that goes into achieving such a high level of success. You should all be proud of yourselves for making it this far—and be prepared to have your ambitions and accomplishments skyrocket in the near future.
            </i>
            <br/><br/>
            <i style={{fontSize: "17px"}}>
            The IRO finals experience has been meticulously crafted by myself and a team—a team that has extensive experience with science competitions—to optimize for the most fun and rewarding experience possible—along with the prestige that comes with placing in an international-level competition with over a thousand entries in its first year. Our goal is to make this the most memorable research event you'll ever attend." says Rishab Jain, President & Chair of Student Board of Advisors.
            </i>
            <br/><br/>
            

           </div>
           <p className="lowertitle">
            The International Research Olympiad is a platform that strives to create and promote research culture among high school students. This is done by providing a variety of services, tools, and support that aim to streamline the research process. The IRO seeks to give students the opportunity to develop their research proficiency, learn new methodologies, and collaborate at an international scale.
            </p>
            </div>
        <div className='row rowpad1' data-aos="fade-up">
        {/* <div className='col' style={{paddingTop: "50px"}}>
                <p className="maintitle bigblueonthewing">Semifinalists - Top 15%</p>
            </div> */}
            
          </div>
          <div className='row rowpad1' data-aos="fade-up" style={{ display: "flex", justifyContent: "center" }}>
            {winners1.map((stat) => (
              <div key={stat.id} className="col" style={{ paddingTop: "20px", display: "flex", justifyContent: "center" }}>
                <div className="card" style={{ width: "18rem" }}>
                  <LazyLoad height={200} once offset={100}>
                    <img src={stat.value} style={{ height: "340px", width: "100%" }} className="card-img-top no-invert-image-partners-css" alt="..." />
                  </LazyLoad>
                  <div className="card-body">
                    <p className="card-text">{stat.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>


          {/* <div className='row rowpad1' data-aos="fade-up">
            {winners2.map((stat) => (
                <div key={stat.id} className="col" style={{paddingTop: "20px"}}>
                  <div class="card" style={{width: "18rem"}}>
                    <img src={stat.value} class="card-img-top" alt="..."/>
                    <div class="card-body">
                      <p class="card-text">{stat.name}</p>
                    </div>
                  </div>
              </div>
            ))}
          </div>
          <div className='row rowpad1' data-aos="fade-up">
            {winners3.map((stat) => (
                <div key={stat.id} className="col" style={{paddingTop: "20px"}}>
                  <div class="card" style={{width: "18rem"}}>
                    <img src={stat.value} class="card-img-top" alt="..."/>
                    <div class="card-body">
                      <p class="card-text">{stat.name}</p>
                    </div>
                  </div>
              </div>
            ))}
          </div> */}
      </div>
  )
}

export default Winners
