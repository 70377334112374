import React from 'react';
import './App.css';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import logo from './irofullpng.webp'; 
import section1 from "./irosection1.png";
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import Box from '@mui/material/Box';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import Backdrop from '@mui/material/Backdrop';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import LightModeIcon from '@mui/icons-material/LightMode';
import BedtimeIcon from '@mui/icons-material/Bedtime';

import { useState, useEffect, forwardRef, Fragment, useRef } from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import firebase from "firebase/compat/app";
import db from './firebase'
import { Margin } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import "aos/dist/aos.css"; 
import { TypeAnimation } from 'react-type-animation';
import AOS from "aos";
import uuid from 'react-uuid';
import Marquee from "react-fast-marquee";



const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Register() {

  const [email, setEmail] = useState('');
  
  const [uID, setUID] = useState(false); 
  const [iroUid, setIroUid] = useState('');
  
  const [successOpenMUI, setSuccessOpenMUI] = useState(false);
  const [failOpenMUI, setFailOpenMUI] = useState(false);
  const trueOrFalseDisableButton = JSON.parse(localStorage.getItem('trueOrFalseDisableButton'));
  const [enterEmailButtonDisable, setEnterEmailButtonDisable] = useState(trueOrFalseDisableButton);
  const [open, setOpen] = useState(false);
  const [documentCount, setDocumentCount] = useState([]);

  const increment = firebase.firestore.FieldValue.increment(1);


  const EmailChecker = async () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let today = new Date()


    if ( re.test(email) ) {
      setSuccessOpenMUI(true);
      setEnterEmailButtonDisable(true);
      localStorage.setItem('trueOrFalseDisableButton', JSON.stringify(true));

      const iroUid = uuid();
      setIroUid(iroUid)

      db.collection("IRO").add({
        email: email,
        date: today,
        uid: iroUid,
      });

      var storyRef = db.collection("Submits").doc("countval");
      storyRef.update({ submitcounts: increment });

      setEmail('');
      setUID(true)

    }
    else {
      setFailOpenMUI(true);

      setEnterEmailButtonDisable(false);
      localStorage.setItem('trueOrFalseDisableButton', JSON.stringify(false));
    }



  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  

  useEffect(() => {
    db.collection("Submits").onSnapshot((snapshot) =>
      setDocumentCount(snapshot.docs.map((doc) => doc.data()))
    );
  }, []);



  const CustomLabel = () => (
    <>
        <TypeAnimation
        sequence={[
            'Email ',
            3000,
            'james@gmail.com ',
            2000,
            'samantha@gmail.com ',
            2000,
            'susan@gmail.com',
            2000,
        ]}
        wrapper="span"
        speed={200}
        style={{ display: 'inline-block', fontSize: '18px' }}
        className="hdgfkajhsgdfkjshadgajhdsg-name"
        repeat={Infinity}
        />
    </>
  );

    
  const handleCloseMUI = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessOpenMUI(false);
    setFailOpenMUI(false);
    setUID(false)
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

      
  return (
    <div>
        <div className="container">


            <div className="emailbox-css" data-aos="fade-up">
              <div>
                {/* <img className="mainimgg" src={logo}></img> */}

                <p className="maintitle">Registration</p>
        

                <p>Is Closed for 2024</p>
              
              </div>
              {/* <div>
                <TextField 
                    className="propfont" 
                    fullWidth id="standard-basic" 
                    label={email.trim() === '' ? <CustomLabel /> : <span style={{ fontSize: '18px' }}>Email</span>}
                    variant="standard" 
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    hidden={enterEmailButtonDisable}
                    
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            {email.trim() !== '' &&
                            <IconButton 
                                sx={{
                                '&:hover': {
                                    background: 'none',
                                },
                                }}
                                onClick={EmailChecker}
                                disabled={enterEmailButtonDisable}
                                hidden={enterEmailButtonDisable}
                            >
                                <ArrowForwardIcon 
                                style={{
                                    color:"black", 
                                    fontSize: '20px',
                                }}
                                />
                            </IconButton>
                            }     
                        </InputAdornment>
                        ),
                      }}
                    
                    />
                    
                    <p hidden={!enterEmailButtonDisable} className="Response-submitted">  <CheckCircleIcon/> Your response was successfully submitted.</p>
                   
                    {documentCount === '0' ? <p style={{marginTop:'7px', color: "gray", fontSize:'17px'}}>2500+ registered</p> : <p style={{marginTop:'7px', color: "gray", fontSize:'17px'}}>{documentCount.map(({ submitcounts }) => Math.round(submitcounts / 50) * 50)}+ registered</p>}
                </div> */}
            </div>
            {/* <div>
                <div>
                <Snackbar open={successOpenMUI} autoHideDuration={5000} onClose={handleCloseMUI}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                    <b>Email Registered!</b>
                    </Alert>
                </Snackbar>
                            
                <Snackbar open={failOpenMUI} autoHideDuration={3000} onClose={handleCloseMUI}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                    <b>Please enter a valid email.</b>
                    </Alert>
                </Snackbar>

                </div>
            </div> */}



        </div>
    </div>
  )
}

export default Register



