import React, { useEffect, useState} from 'react'
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import AOS from "aos";
import '../App.css'
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import db from '../firebase'
import { HashLink, NavHashLink } from 'react-router-hash-link';

import './About.css'
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import Backdrop from '@mui/material/Backdrop';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import LightModeIcon from '@mui/icons-material/LightMode';
import BedtimeIcon from '@mui/icons-material/Bedtime';

function MediaContact() {

    const trueOrFalseDisableButton = JSON.parse(localStorage.getItem('trueOrFalseDisableButtont'));
  const [enterEmailButtonDisablet, setEnterEmailButtonDisablet] = useState(trueOrFalseDisableButton);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    const Submit = () => {
        db.collection("IRO-Media-Outreach").add({
          FirstName: firstName,
          LastName: lastName,
          Email: email,
          Subject: subject,
          Message: message,
        });
        setFirstName('')
        setLastName('')
        setEmail('')
        setSubject('')
        setMessage('')
    
        setEnterEmailButtonDisablet(true);
        localStorage.setItem('trueOrFalseDisableButtont', JSON.stringify(true));
      }

  return (
    <div>
        <div className="container">
             <div className='row rowpad1' data-aos="fade-up">

                <div className='col' style={{paddingTop: "50px"}} data-aos="fade-up">
                <p className="maintitle">Media Outreach</p>
                </div>


                <div class="input-group mogsep" hidden={enterEmailButtonDisablet}>
                <span class="input-group-text">First and last name</span>
                <input type="text" aria-label="First name" class="form-control" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                <input type="text" aria-label="Last name" class="form-control" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                </div>

                <div class="input-group mogsep" hidden={enterEmailButtonDisablet}>
                <span class="input-group-text">Email</span>
                <input type="email" aria-label="Email" class="form-control" value={email} onChange={(e) => setEmail(e.target.value)}/>

                </div>


                <div class="input-group mogsep" hidden={enterEmailButtonDisablet}>
                <span class="input-group-text">Subject</span>
                <input type="text" aria-label="Subject" class="form-control" value={subject} onChange={(e) => setSubject(e.target.value)} />

                </div>



                <div style={{paddingTop: "40px"}} hidden={enterEmailButtonDisablet}></div>
                <TextField
                id="outlined-multiline-static"
                label="Your Message"
                multiline
                rows={4}
                value={message} onChange={(e) => setMessage(e.target.value)}
                hidden={enterEmailButtonDisablet}
                />

                {message && subject && email && lastName && firstName ?
                <button class="btn-submit-news-button" style={{width: "130px"}} type="submit" onClick={Submit} hidden={enterEmailButtonDisablet}>Submit</button>
                :
                <Tooltip title="Please fill all fields" hidden={enterEmailButtonDisablet}>
                    <Button class="btn-submit-news-button" style={{width: "130px"}} type="submit" disabled >Submit</Button>
                </Tooltip>
                }

                <p hidden={!enterEmailButtonDisablet} className="Response-submitted">Your response was successfully submitted.</p>

                </div>
        </div>
    </div>
  )
}

export default MediaContact