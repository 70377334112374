import React from 'react';

function ClubDirectory() {
  return (
    <div style={{
        width: '100vw',
        paddingLeft: '80px',
        paddingRight: '80px',
    }}>
    <div className='row rowpad1'>
        {/* <div className='col' style={{paddingTop: "50px"}} data-aos="fade-up">
          <p className="maintitle" style={{textAlign: 'center', marginBottom: '50px'}}>The Curriculum</p>
        </div> */}
      </div>
      <div className='row psuhonbttomsmallscreen'>
        <div className='col' data-aos="fade-up">
        
        <div className="container" style={{
            marginBottom: '25px'
        }} >
  
          {/* <img src={logo} className="id-logo-css-iro-dhfgasdfjhgasdjfhagsdkfhasdfgsjdhf" alt=""/> */}

          <h1 className="bigblueonthewing">Research Club Directory:</h1>
          <br className="br"/>
          <h4>Complete listing of all existing IRO-affiliated Research Clubs</h4>
        
        </div>
       </div>
       
        </div>
        <iframe style={{
            width: '100%',
            minHeight: '500px'
        }} src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQVQC2Rc4rxvrE3EXSMtBLtYXjldkvfw0B4_o69XasVe09peVxqxSt_q_Ls6vQfIMaTCQIg8NDDtgd0/pubhtml?gid=0&amp;single=true&amp;widget=false&amp;headers=false&amp;chrome=false"></iframe>

</div>
  );
}

export default ClubDirectory;


