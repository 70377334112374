import React, { useEffect } from 'react'
import Skeleton from '@mui/material/Skeleton';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import TextField from '@mui/material/TextField';
import AOS from "aos";
import { Link } from 'react-router-dom';
import '../App.css'

function Resources() {

  useEffect(() => {
    AOS.init({ duration: 2000 }); //might need to be AOS
  }, []);


  return (
    <div className='container'>
        <div className='row rowpad1'>
          <div className='col' style={{paddingTop: "50px"}} data-aos="fade-up">
            <p className="maintitle">How to Prepare for IRO</p>
          </div>
        </div>

        <div className='row rowpad1'>
          <div className='col' data-aos="fade-up">
          {/* <Skeleton variant="rectangular" width={500} height={300} /> */}

          <iframe width="553" height="310" src="https://www.youtube.com/embed/tFdOjXrVLp0?si=Z-8J_1tPLokDcsAK" className="rishab-jain-video-css" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

          {/* <iframe width="560" height="310" src="https://www.youtube.com/watch?v=tFdOjXrVLp0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className="rishab-jain-video-css"></iframe> */}
          </div>

          <div className='col' data-aos="fade-up">
           <p className="lowertitle-ls">The International Research Olympiad is a competition that doesn't just rely on memorization of content or a specific type of problem. <br/><br/> It aims to test crucial analytical skills that are essential to succeed as a <b>researcher</b>.</p>
          </div>
        </div>

        <div className='row rowpad1'>
          <div className='col' style={{paddingTop: "50px"}} data-aos="fade-up">
            <p className="maintitle">The Curriculum</p>
          </div>
        </div>
        <div className='row '>
          <div className='col' data-aos="fade-up">
          <p className="lowertitle">The IRO doesn’t have a specific textbook to read. We do not believe that making students memorize an entire textbook is beneficial. However, there are several ways you can prepare for the IRO. We are testing critical thinking and analytical skills—and the open examination will test your ability to read science literature. In preparation for the IRO open exam, our goal is for students to (1) learn to read papers—a critical skill for scientists, and (2) learn more about subjects they are interested in. <br/><br/>However, there will be some aspect of breadth on the exam — and being able to read a variety of papers i.e. not just math papers but also biology ones, will be helpful. Below are several papers that we recommend you read for now (~3 hours of reading). More resources will be released when registration opens.</p>
          <p className="lowertitle">Try reading some of these papers—don't worry about not understanding some words; focus on the context and the points made instead.</p>

          <Stack direction="row" style={{paddingTop: "10px", overflowX:'auto'}} spacing={1}>
            <a href="https://bmcneurosci.biomedcentral.com/counter/pdf/10.1186/s12868-016-0262-y.pdf" target="_blank"> <Chip icon={<AttachFileIcon />} label="Enzymatic Brain Tissue Dissociation" variant="outlined" /> </a>
            <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9454000/" target="_blank"> <Chip icon={<AttachFileIcon />} label="Biomaterials for Tissue Regeneration" variant="outlined" /> </a>
            <a href="https://arxiv.org/pdf/1310.4546.pdf" target="_blank"> <Chip icon={<AttachFileIcon />} label="Word and Phrase Distributed Representations" variant="outlined" /> </a>
          </Stack>
          </div>
        </div>


      </div>
  )
}

export default Resources
