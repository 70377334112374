import React from 'react'
import Announcement from '../components/annoucement';
import LazyLoad from 'react-lazyload';
import finaldiscussion from "../finalist_photos/finalsdiscussion-min.webp"
import CelebrationIcon from '@mui/icons-material/Celebration';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function StartIROClub() {

  const gotostripe = () => {
    window.location.href = "https://register.internationalresearcholympiad.org/b/eVadSR8SDexrfhmdQS"
    
  }

  return (
    <div>
    <Announcement/>
      <div className="container">
      {/* <iframe className="hwotaniroclub" src="https://drive.google.com/file/d/1Tw5QY0IN04k2v8KySfNXLZzMLJnWaRWL/preview"  allow="autoplay"></iframe> */}

      <div className="row rowpad1">
      <h1 className="bigblueonthewing">IRO Club Guide</h1>


      <iframe src="https://docs.google.com/document/d/1P7lshfP_WtwIy_rqoCk3_5aAbd9ftlj-3pH4FTC0LrU/preview" style={{width: '100%', height: '100vh'}} frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>


          

      

      </div>

   
      </div>
    </div>
  )
}

export default StartIROClub

