import React from 'react';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Announcement = () => {
  return (
    <>
        <div className=" containere" data-aos="zoom-in-up">

        <div className="infoe">
        <p style={{marginTop: '8px', fontSize: '16px'}}>
            {/* <strong className="sdflknsrj" style={{fontSize: '16px', fontFamily: "serif"}}>Register before it's too late!</strong> */}
            <Typography variant="overline" >
            <b >ANNouncement:</b> Official IRO Textbook Released
            </Typography>
            <a href="https://amzn.to/4inoYWH" style={{color: "black"}} placement="right-start">
            <ArrowForwardIcon style={{marginBottom: "2px"}}/>
            </a>
        </p>
        
        </div>
        </div>
        
    </>
  );
};

export default Announcement;
