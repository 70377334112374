import React from 'react';
import LazyLoad from 'react-lazyload';
import book from "../finalist_photos/book.webp"
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Book = () => {
    const loginpage = () => {
        window.location.href = "https://amzn.to/4inoYWH";
    
    }

  return (
    <>
        <div className='row'>

        <div class="col mainlscreen" data-aos="fade-up">
              
              <LazyLoad height={200} once offset={100}>
                <img className="mainimg shadow-lg rounded" src={book} style={{ width: '50%', height: 'auto' }}></img>
              </LazyLoad>
            </div>
          
            <div className='col' style={{paddingTop:"70px"}}>
            <p className="secondarytitle">Master the IRO with Our Comprehensive Textbook</p>
              <p className="lowertitle2">The International Research Olympiad Textbook helps students develop research and analytical skills across scientific disciplines. It offers content reviews, practice questions, and mock exams, with additional online resources like daily challenges. The IRO consists of an open exam with 4 research papers and 32 MCQs, followed by a semifinal and in-person final.</p>

              <button className="lowertitle2 secondinsideytwo underline-animation" type="submit" onClick={loginpage}>Buy the Textbook</button><ArrowForwardIcon style={{marginBottom: "7px"}}/>
            </div>
            
          </div>
        
    </>
  );
};

export default Book;