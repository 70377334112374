import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography';


function Winners() {
  return (
      <div className="container">
        <div className='col' style={{paddingTop: "50px"}}>
        <Typography style={{fontSize: "1.5ch"}} variant="overline" display="block"  gutterBottom>
        3/29/24
          </Typography>
          <div className='col' style={{paddingTop: "50px"}}>
             <p className="maintitle bigblueonthewing">Semifinalists - Top 15%</p>
         </div>
         <p className="lowertitle">
         International Research Olympiad Olympiad Semi-Finalists Announced.
            </p>
            <p className="lowertitle">
            Congratulations to the IRO semi-finalists. We believe your potential in research is unlimited and deep. We stand committed to making this community into a close, innovative, welcoming, and supportive society around student research aspirations,” says Rishab Jain, Student Board Member & President, IRO.
            </p>
            <p className="lowertitle">
            The International Research Olympiad is a platform that strives to create and promote research culture among high school students. This is done by providing a variety of services, tools, and support that aim to streamline the research process. The IRO seeks to give undergraduate students the opportunity to develop their research proficiency, learn new methodologies, and collaborate at an international scale.
            </p>
            
            </div>
        <div className='row rowpad1' data-aos="fade-up">
        {/* <div className='col' style={{paddingTop: "50px"}}>
                <p className="maintitle bigblueonthewing">Semifinalists - Top 15%</p>
            </div> */}
            <ul class="list-group list-group-flush">
            <li class="list-group-item">Vayun C.</li>
            <li class="list-group-item">Aarush K.</li>
            <li class="list-group-item">Akshay K.</li>
            <li class="list-group-item">Vishnu M.</li>
            <li class="list-group-item">Antara C.</li>
            <li class="list-group-item">Prashanth G.</li>
            <li class="list-group-item">Anisha P.</li>
            <li class="list-group-item">Ameya S.</li>
            <li class="list-group-item">Moeen M.</li>
            <li class="list-group-item">Platon G.</li>
            <li class="list-group-item">Katherine C.</li>
            <li class="list-group-item">Artem K.</li>
            <li class="list-group-item">Arish S.</li>
            <li class="list-group-item">Adhrith V.</li>
            <li class="list-group-item">Likhith K</li>
            <li class="list-group-item">Sarthak K.</li>
            <li class="list-group-item">Ram S.</li>
            <li class="list-group-item">Sakethram R.</li>
            <li class="list-group-item">Anya C.</li>
            <li class="list-group-item">Chegu V.</li>
            <li class="list-group-item">Nikhita B.</li>
            <li class="list-group-item">Elaab T.</li>
            <li class="list-group-item">Trashgim M.</li>
            <li class="list-group-item">Neo R.</li>
            <li class="list-group-item">Youngjae S.</li>
            <li class="list-group-item">Ishaan G.</li>
            <li class="list-group-item">Rania R.</li>
            <li class="list-group-item">Amit B.</li>
            <li class="list-group-item">Stanley H.</li>
            <li class="list-group-item">Shang-jiun E.</li>
            <li class="list-group-item">Areej A.</li>
            <li class="list-group-item">Alyssa Y.</li>
            <li class="list-group-item">Elizabeth H.</li>
            <li class="list-group-item">Justin Y.</li>
            <li class="list-group-item">Abigail R.</li>
            <li class="list-group-item">Bethelehem K.</li>
            <li class="list-group-item">Amritha P.</li>
            <li class="list-group-item">Salman A.</li>
            <li class="list-group-item">Twisha H.</li>
            <li class="list-group-item">Keisha K.</li>
            <li class="list-group-item">Hakim S.</li>
            <li class="list-group-item">Lesbek A.</li>
            <li class="list-group-item">Aadi S.</li>
            <li class="list-group-item">Abhimanyu C.</li>
            <li class="list-group-item">Raksha K.</li>
            <li class="list-group-item">Andrew Z.</li>
            <li class="list-group-item">Sungwoo C.</li>
            <li class="list-group-item">Shayaan U.</li>
            <li class="list-group-item">Siddharth T.</li>
            <li class="list-group-item">Aditya L.</li>
            <li class="list-group-item">Shreyaan C.</li>
            <li class="list-group-item">Seraph Y.</li>
            <li class="list-group-item">Egor C.</li>
            <li class="list-group-item">Elan S.</li>
            <li class="list-group-item">Nicholas R.</li>
            <li class="list-group-item">Jason Y.</li>
            <li class="list-group-item">Prakriti A.</li>
            <li class="list-group-item">Serena D.</li>
            <li class="list-group-item">Lasya P.</li>
            <li class="list-group-item">Michael Q.</li>
            <li class="list-group-item">Yi L.</li>
            <li class="list-group-item">Caiman M.</li>
            <li class="list-group-item">Jumanah M.</li>
            <li class="list-group-item">Surdish R.</li>
            <li class="list-group-item">Madhavendra T.</li>
            <li class="list-group-item">Yifeng C.</li>
            <li class="list-group-item">Pavi J.</li>
            <li class="list-group-item">Eeshaan V.</li>
            <li class="list-group-item">Aryan S.</li>
            <li class="list-group-item">Nandita M.</li>
            <li class="list-group-item">Saathvik V.</li>
            <li class="list-group-item">Arnav E.</li>
            <li class="list-group-item">Nainika S.</li>
            <li class="list-group-item">Klara M.</li>
            <li class="list-group-item">Atul G.</li>
            <li class="list-group-item">Samyra M.</li>
            <li class="list-group-item">Sai C.</li>
            <li class="list-group-item">Siddharth S.</li>
            <li class="list-group-item">Oscar M.</li>
            <li class="list-group-item">Rohith L.</li>
            <li class="list-group-item">Sreeram B.</li>
            <li class="list-group-item">Arav C.</li>
            <li class="list-group-item">Sriya G.</li>
            <li class="list-group-item">Amogh B.</li>
            <li class="list-group-item">Rajat R.</li>
            <li class="list-group-item">Hongyi W.</li>
            <li class="list-group-item">Rudransh P.</li>
            <li class="list-group-item">Evan H.</li>
            <li class="list-group-item">Ethan S.</li>
            <li class="list-group-item">Saahir V.</li>
            <li class="list-group-item">Shivaani A.</li>
            <li class="list-group-item">Sritam P.</li>
            <li class="list-group-item">Esther L.</li>
            <li class="list-group-item">Aditya K.</li>
            <li class="list-group-item">Alex Z.</li>
            <li class="list-group-item">Nayan P.</li>
            <li class="list-group-item">Kenisha V.</li>
            <li class="list-group-item">Claire C.</li>
            <li class="list-group-item">Om S.</li>
            <li class="list-group-item">Jaansi P.</li>
            <li class="list-group-item">Vineet S.</li>
            <li class="list-group-item">Hayden R.</li>
            <li class="list-group-item">Nina Z.</li>
            <li class="list-group-item">Avani A.</li>
            <li class="list-group-item">Tanvi G.</li>
            <li class="list-group-item">Roshini S.</li>
            <li class="list-group-item">Angel Z.</li>
            <li class="list-group-item">Narendran K.</li>
            <li class="list-group-item">William L.</li>
            <li class="list-group-item">Sophia H.</li>
            <li class="list-group-item">Aarish T.</li>
            <li class="list-group-item">Ximurong Z.</li>
            <li class="list-group-item">Hafsah M.</li>
            <li class="list-group-item">Nassar B.</li>
            <li class="list-group-item">Pradhyumna V.</li>
            <li class="list-group-item">Alicia Z.</li>
            <li class="list-group-item">Conor M.</li>
            <li class="list-group-item">Shivansh B.</li>
            <li class="list-group-item">Azimullah R.</li>
            <li class="list-group-item">Shaan D.</li>
            <li class="list-group-item">Devaki R.</li>
            <li class="list-group-item">Ansh J.</li>
            <li class="list-group-item">Anishka V.</li>
            <li class="list-group-item">Pratyay A.</li>
            <li class="list-group-item">Kshithi P.</li>
            <li class="list-group-item">Adwita N.</li>
            <li class="list-group-item">Radia M.</li>
            <li class="list-group-item">Tristan P.</li>
            <li class="list-group-item">Vishwa V.</li>
            <li class="list-group-item">Zhangir M.</li>
            <li class="list-group-item">Keshav W.</li>
            <li class="list-group-item">Carlos M.</li>
            <li class="list-group-item">Ruuhan A.</li>
            <li class="list-group-item">Anshi M.</li>
            <li class="list-group-item">Shiven D.</li>
            <li class="list-group-item">Akash K.</li>
            <li class="list-group-item">Hridhaan S.</li>
            <li class="list-group-item">Sara W.</li>
            <li class="list-group-item">Grace L.</li>
            <li class="list-group-item">Nguyen H.</li>
            <li class="list-group-item">Edward K.</li>
            <li class="list-group-item">Aradhya V.</li>
            <li class="list-group-item">Navya K.</li>
            <li class="list-group-item">Tanisha S.</li>
            <li class="list-group-item">Akhil P.</li>
            <li class="list-group-item">Soneesh K.</li>
            <li class="list-group-item">Medha K.</li>
            <li class="list-group-item">Ishaan P.</li>
            <li class="list-group-item">Yuvha K.</li>
            <li class="list-group-item">Tyler L.</li>
            </ul>   
          </div>
      </div>
  )
}

export default Winners
