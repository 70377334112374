import React from 'react'

function TermOfService() {
  return (
    <div>
        <div className="container">
          <iframe className="termsacondsitonfs" src="https://docs.google.com/document/d/e/2PACX-1vTHZ-_OdNXn9k3IV0f4kFnzHv817BD43cphfRCvUogZpklu8M_VYRxlaYbEHEMxsXJIVA4C3vxfwceM/pub?embedded=true"></iframe>
        </div>
    </div>
  )
}

export default TermOfService